import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/street';

export default {
    search_new({text, parent_id, page}) {
        return axios.post(`${RESOURCE_NAME}/search-new-`, { search_text : text, page: page, parent_id: parent_id })
    },
    get_by_id(payload) {
        return axios.post(`${RESOURCE_NAME}/get-by-id-`, { elements: payload })
    },
    get_one_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/one-by-id`, { params: payload })
    },
};