<template>
  <div class="input-wrapper" style="position: relative;"
       v-click-outside="{handler: onClickOutside, closeConditional}"
  >
    <MainModal
        :main="{ component: current_modal.name, title: current_modal.title }"
        :button="{ iconActivator: false, buttonHidden: true }"
        :item="modal_item_data"
        :modal="show_modal_dialog"
        @updateItemModal="afterModalClose"
    />
    <v-text-field
        type="text"
        readonly
        :clearable="clearable"
        :disabled="disabled"
        :value="input_value"
        :label="label"
        :placeholder="placeholder"
        :filled="filled"
        :loading="loading"
        hide-details
        @focusin="setCoordinates"
        @click.native="onClick"
        color="grey lighten-1"
        :class="{'req-star': getRequired}"
        @click:clear="clearAll"
    >
      <template v-slot:append v-if="!multiple">
        <v-menu style="top: -12px" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" :disabled="disabled">
              mdi-dots-vertical
            </v-icon>
          </template>
          <v-list dense nav>
            <v-list-item @click="createNewModalElement">
              <v-list-item-icon class="mr-3">
                <v-icon color="success" size="18">
                  mdi-plus-circle-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Створити</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="editModalElement" v-if="value">
              <v-list-item-icon class="mr-3">
                <v-icon color="secondary" size="18">
                  mdi-pencil-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Змінити</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

<!--          <template>-->
<!--            <v-icon color="grey">-->
<!--              {{ focus_in ? 'mdi-menu-up' : 'mdi-menu-down' }}-->
<!--            </v-icon>-->
<!--          </template>-->

      </template>
    </v-text-field>
    <v-menu v-model="show_dropdown"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            transition="slide-y-transition"
            :close-on-click="true"
            :close-on-content-click="false"
            :min-width="width"
            :max-width="width"
            :style="style_"
    >
      <div class="dont-close-this-element"
           v-show="multiple && this.selected_multiple.length"
           style="background: #ffffff">
        <div class="d-flex flex-nowrap px-2 pt-2 dont-close-this-element" style="font-size: 13px; font-weight: bold">
          <div style="flex: 0 50%" class="dont-close-this-element">
            <span class="dont-close-this-element" style="color: #4CAF50">Обрано:</span> {{
              this.selected_multiple.length
            }}
          </div>
          <div style="flex: 1; text-align: right">
            <span class="dont-close-this-element"
                  @click.stop="clearAll"
                  style="text-align: right; color: #4CAF50; text-decoration: underline; cursor:pointer;">
              Очистити
            </span>
          </div>
        </div>
        <v-chip-group
            :column="selected_multiple.length <= 4"
            class="dont-close-this-element px-2 pb-0 pt-0"
        >
          <v-chip :small="selected_multiple.length <= 4"
                  label
                  v-for="item in selected_multiple"
                  :key="item.value"
                  close class="dont-close-this-element"
                  style="font-size: 11px"
                  @click:close="onChipCloseClick(item)">
            {{ item.text }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="px-2 py-3 dont-close-this-element elevation-5 white">
        <div style="position: relative" class="dont-close-this-element">
          <input
              type="text"
              v-model="search"
              placeholder="Введіть текст для пошуку"
              class="dont-close-this-element select-search-input"
              ref="address_input"
          >
          <div style="position: absolute; top: 50%; right: 8px; transform: translateY(-50%)">
            <v-icon size="18" color="grey" @click="search = ''">mdi-close</v-icon>
          </div>
        </div>

      </div>
      <v-card
          flat
          class="select-wrapper elevation-5 transition-fast-in-fast-out dont-close-this-element"
          style="border-top-right-radius: 0; border-top-left-radius: 0;"
          v-scroll.self="infiniteScrolling"
      >
        <div>
          <v-list dense v-if="items.length">
            <v-list-item
                v-for="(elem, index) in items"
                :key="index"
                class="pl-2 pr-8 text-break dont-close-this-element"
                style="border-bottom: 1px solid #d4d4d4; border-radius: 0; height: 46px; min-width: 260px"
                @click="doSelect(Object.assign(elem, {emit: true}))"
                :id="index === 0 ? 'scroll-element' : ''"
            >
              <v-list-item-icon class="list-content dont-close-this-element ml-2 mr-5">
                <v-icon :color="getCurrentIcon(elem.element_type, 'color')" size="23"
                        class="list-content dont-close-this-element mt-1">
                  {{ getCurrentIcon(elem.element_type, 'icon') }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="list-content dont-close-this-element">
                {{ elem.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-if="!items.length && search">
            <v-list-item class="pa-2 align-center dont-close-this-element">
              <v-list-item-icon class="mr-5 ml-3 align-self-center dont-close-this-element">
                <v-icon size="43" color="error lighten-1 dont-close-this-element">
                  mdi-emoticon-sad-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-medium px-3 dont-close-this-element">
                <v-list-item-title class="dont-close-this-element" style="font-size: .94rem">
                  Нічого не знайшли...
                </v-list-item-title>
                <v-list-item-subtitle class="pt-1 dont-close-this-element">
                  спробуйте ввести щось інше
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-if="!items.length && !search">
            <v-list-item class="pa-2 align-center dont-close-this-element">
              <v-list-item-icon class="mr-5 ml-3 align-self-center dont-close-this-element">
                <v-icon size="43" color="error lighten-1 dont-close-this-element">
                  mdi-emoticon-sad-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-medium px-3 dont-close-this-element">
                <v-list-item-title class="dont-close-this-element" style="font-size: .94rem">
                  Відсутні елементи для відображення.
                </v-list-item-title>
                <v-list-item-subtitle class="pt-1 dont-close-this-element">
                  Спробуйте задати інший критерій, або використайте пошук
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import streetAPI from "@/utils/axios/street"
import streetAPI_ from "@/utils/axios/street_"
import cityAPI from "@/utils/axios/city"
import cityAreaAPI from "@/utils/axios/city_area"
import buildingAPI from "@/utils/axios/bilding"
import amalgamatedHromadaAPI from "@/utils/axios/amalgamanted_hromada"
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCityIcon, getStreetIcon, getBuildingIcon} from "@/utils/icons";

export default {
  name: "AddressElementSelect",
  props: {
    value: {
      type: [Number, Array],
      default: null
    },
    use_parent_id: {
      type: Boolean,
      default: false
    },
    parent_id: {
      type: [Number, String, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    req: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    select_type: {
      type: String,
      default: null
    },
    auto_fill_when_first: {
      type: Boolean,
      default: false
    },
    style_: {
      type: String,
      default: ''
    },
    field_name: {
      style: String,
      default: ''
    }
  },
  computed: {
    currentAPI() {
      switch (this.select_type) {
        case 'street':
          return streetAPI
        case 'street-with-city-area':
          return streetAPI_
        case 'building':
          return buildingAPI
        case 'city':
          return cityAPI
        case 'city-area':
          return cityAreaAPI
        case 'amalgamated_hromada':
          return amalgamatedHromadaAPI
        default:
          return undefined
      }
    },
    current_modal() {
      return this.components_list[this.select_type] || {}
    },
    getCurrentIcon() {
      switch (this.select_type) {
        case 'street':
          return getStreetIcon
        case 'street-with-city-area':
          return getStreetIcon
        case 'building':
          return getBuildingIcon
        case 'city':
          return getCityIcon
        case 'amalgamated_hromada':
          return (element, attr) => ({'color': 'success', 'icon': 'mdi-map-legend'})[attr]
        case 'city-area':
          return (element, attr) => ({'color': 'success', 'icon': 'mdi-map-legend'})[attr]
        default:
          return undefined
      }
    },
    getRequired() {
      if (!this.req) return false
      if (this.multiple) return !this.selected_multiple.length
      return !this.selected_single.value
    }
  },
  components: {
    MainModal: () => import('@/components/modal/MainModal.vue')
  },
  data() {
    return {
      x: 0,
      y: 0,
      width: 0,
      show_dropdown: false,
      focus_in: false,
      by_id_loaded: false,
      search: '',
      selected_single: {
        text: '',
        value: null,
        parent_id: null,
        element_type: null
      },
      selected_multiple: [],
      items: [],
      page: 1,
      loading: false,
      page_loaded: 1,
      value_watcher: null,
      parent_id_watcher: null,
      search_watcher: null,
      input_value: '',
      innerClick: false,
      value_loading: false,
      auto_filled: false,
      components_list: {
        'street': {name: 'Street', title: 'Вулиці'},
        'street-with-city-area': {name: 'Street', title: 'Вулиці'},
        'building': {name: 'Building', title: 'Будинки'},
        'city': {name: 'City', title: 'Населені пункти'},
        'city-area': {name: 'CityArea', title: 'Райони міста'},
        'amalgamated_hromada': {name: 'AmalgamatedHromada', title: 'Об’єднані громади'},
      },
      modal_item_data: {},
      show_modal_dialog: false
    }
  },
  methods: {
    selectedDataAndValuesIsequal(left_data, right_data) {
      if (this.multiple) {
        const left_new_data = JSON.stringify(Array.from(left_data).sort())
        const right_new_data = JSON.stringify(Array.from(right_data).sort())

        return left_new_data === right_new_data
      } else {
        return left_data === right_data
      }
    },
    transform_all_to_array(payload) {
      if (payload === undefined || payload === null) return []

      if (typeof payload === 'number') return [payload]

      if (typeof payload === 'string') return [payload]

      if (typeof payload === 'object') {
        if (Array.isArray(payload)) {
          return payload
        } else {
          return []
        }
      }

    },
    checkOnIncludes(new_data, old_data) {
      const exclude = []

      if (!new_data.length) return old_data

      old_data.forEach(i => {
        const present = new_data.includes(i)
        if (!present) {
          exclude.push(i)
        }
      })
      return exclude
    },
    watch_search_func() {
      this.search_watcher = this.$watch(
          'search',
          {
            deep: false,
            immediate: false,
            handler(payload) {
              this.fetchData(payload)
            }
          }
      )
    },
    watch_value_func() {
      this.value_watcher = this.$watch(
          'value',
          {
            deep: false,
            immediate: true,
            handler(new_payload, old_payload) {
              const new_payload_json = JSON.stringify(new_payload)
              const old_payload_json = JSON.stringify(old_payload)

              if (new_payload_json === old_payload_json) {
                const new_payload_empty = this.checkForEmpty(new_payload)
                const old_payload_empty = this.checkForEmpty(old_payload)

                if (new_payload_empty && old_payload_empty) {
                  this.selectedIsEmpty()
                }
                return;
              }

              if (this.multiple) {
                const values_id_equal = this.selectedDataAndValuesIsequal(
                    this.selected_multiple.map(i => i.value),
                    new_payload
                )

                if (!values_id_equal) {
                  this.value_loading = true

                  const value_is_empty = this.checkForEmpty(new_payload)

                  if (!value_is_empty) {
                    this.currentAPI.get_by_id(new_payload)
                        .then(response => response.data)
                        .then(data => {
                          this.selected_multiple = []
                          data.forEach(item => {
                            this.selected_multiple.push(
                                {
                                  text: item.text,
                                  value: item.value,
                                  parent_id: item.parent_id,
                                  element_type: item.element_type
                                }
                            )
                          })
                          this.generate_input_text()
                        })
                        .finally(() => {
                          this.value_loading = false
                        })
                  } else {
                    this.value_loading = false
                  }

                }
              } else {
                const value_is_empty = this.checkForEmpty(new_payload)
                if (!value_is_empty) {

                  if (this.selected_single.value === new_payload) return;

                  this.currentAPI.get_by_id(new_payload)
                      .then(response => response.data)
                      .then(data => {
                        this.selected_single.value = data.value
                        this.selected_single.text = data.text
                        this.selected_single.parent_id = data.parent_id
                        this.selected_single.element_type = data.element_type

                        this.generate_input_text()
                      })
                      .finally(() => {
                        this.value_loading = false
                      })
                }
                {
                  if (this.auto_filled) {
                    this.doEmit()
                    this.emitChanges()
                  } else {
                    this.selectedIsEmpty()
                  }
                }
              }
            }
          }
      )
    },
    watch_parent_id_func() {
      this.value_watcher = this.$watch(
          'parent_id',
          {
            deep: false,
            immediate: false,
            handler(new_payload, old_payload) {
              const new_payload_json = JSON.stringify(new_payload)
              const old_payload_json = JSON.stringify(old_payload)

              if (new_payload_json === old_payload_json) {
                if (this.items.length === 0 && !this.loading) {
                  this.fetchData('', 1, false, new_payload)
                  return
                }
                return
              }

              if (!this.value_loading) {
                if (this.multiple) {
                  if (!this.selected_multiple.length) {
                    this.fetchData()
                  } else {
                    const tr_new_payload = this.transform_all_to_array(new_payload)
                    const tr_old_payload = this.transform_all_to_array(old_payload)

                    const exclude = this.checkOnIncludes(tr_new_payload, tr_old_payload)

                    if (this.multiple) {
                      let excluded_count = 0

                      exclude.forEach(e => {
                        const rows = this.selected_multiple.filter(i => i.parent_id === e)

                        rows.forEach(row => {
                          this.selected_multiple.splice(this.selected_multiple.indexOf(row), 1)
                          excluded_count += 1
                        })
                      })

                      if (excluded_count) {
                        this.generate_input_text()

                        this.doEmit()
                      }
                    } else {
                      this.selected_single.value = null
                      this.selected_single.text = ''
                      this.selected_single.parent_id = null
                      this.selected_single.element_type = null

                      this.input_value = ''

                      this.items = []

                      this.doEmit()
                    }

                    this.fetchData()
                  }
                } else {
                  if (!this.selected_single.value) {
                    this.fetchData('', 1, false, new_payload)
                  } else {
                    this.selected_single.value = null
                    this.selected_single.text = ''
                    this.selected_single.parent_id = null
                    this.selected_single.element_type = null
                    this.input_value = ''

                    this.items = []

                    this.doEmit()
                  }
                }
              } else {
                if (!this.loading) {
                  this.fetchData('', 1, false, new_payload)
                }
              }
            }
          }
      )
    },
    checkForEmpty(value) {
      if (value === undefined || value === null) return true

      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          return !value.length
        }
      }

      return typeof value !== 'number'
    },
    getParentForFetch(new_parent_id = null) {
      const local_parent_id = new_parent_id ? new_parent_id : this.parent_id

      if (local_parent_id === undefined || local_parent_id === null) return null

      if (typeof local_parent_id === 'object') {
        if (Array.isArray(local_parent_id)) {
          if (local_parent_id.length) {
            return local_parent_id
          }
        }
      }

      if (typeof local_parent_id === 'number') return local_parent_id

      if (typeof local_parent_id === 'string') return local_parent_id

      return null
    },
    fetchData(text = '', page = 1, push = false, parent_id = null) {
      const payload = {
        text: text,
        page: page,
        parent_id: this.getParentForFetch(parent_id)
      }

      this.loading = true

      if (this.page === 1) {
        this.page = 1
        this.page_loaded = 1
      }

      this.currentAPI.search_new(payload)
          .then(response => response.data)
          .then(data => {
            if (push) {
              data.forEach(item => this.items.push(item))
            } else {
              this.items = data
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.loading = false

            if (this.auto_fill_when_first && this.items.length === 1 && !push && !text && page === 1) {
              let load_auto = false

              if (this.multiple) {
                if (!this.selected_multiple.length) {
                  load_auto = true
                }
              } else {
                if (!this.selected_single.value) {
                  load_auto = true
                }
              }
              if (load_auto) {
                const payload_for_select = Object.assign({}, this.items[0], {emit: true})
                this.doSelect(payload_for_select)
              }

              this.auto_filled = true
            }
          })
    },
    infiniteScrolling(e) {
      const height = 400
      const by_page = 15
      const first = e.target.getElementsByClassName('v-list-item') || []
      const row_height = (first[0] || {}).offsetHeight || 48
      const offset = e.target.scrollTop
      const visible_count = Math.floor(height / row_height)
      const current_visible = Math.ceil((offset - 8) / row_height) + (by_page - visible_count) + 2
      let current_page = Math.ceil(current_visible / by_page)

      setTimeout(() => {
        if (current_page > this.page && current_page > this.page_loaded && !this.loading) {
          this.loading = true
          this.page++
          this.page_loaded++

          this.fetchData(this.search, current_page, true)
        }
      }, 700);
    },
    closeConditional(e) {
      const target_c = e.target.classList.contains('dont-close-this-element')
      let parent_c = e.target.parentElement
      let parent_d = e.target.parentElement.parentElement

      if (parent_c) {
        parent_c = parent_c.classList.contains('dont-close-this-element')
      } else {
        parent_c = false
      }

      if (parent_d) {
        parent_d = parent_d.classList.contains('dont-close-this-element')
      } else {
        parent_d = false
      }

      return !target_c && !parent_c && !parent_d
    },
    onClickOutside() {
      this.show_dropdown = false
      this.focus_in = false
    },
    setCoordinates(e) {
      if (this.disabled) return
      const coordinates = e.target.getBoundingClientRect()
      this.x = Math.ceil(coordinates.x) - 12
      this.y = Math.ceil(coordinates.y) + 30
      this.width = e.target.offsetWidth + 55;
    },
    onClick() {
      if (this.disabled) return
      this.focus_in = true
      this.show_dropdown = true

      this.page = 1
      this.page_loaded = 1

      if (!this.items.length && this.use_parent_id) {
        this.fetchData()
      }

      this.$nextTick(() => {
        const el = document.getElementById('scroll-element')
        if (el) {
          setTimeout(() => {
            el.scrollIntoView({block: "start"})
            this.$refs.address_input.focus()
          }, 300)
        }

      })

    },
    generate_input_text() {
      if (this.multiple) {
        this.input_value = this.selected_multiple.map(i => i.text).join('; ')
      } else {
        this.input_value = this.selected_single.text || ''
      }
    },
    doSelectSingle(payload) {
      this.selected_single.value = (payload || {}).value || null
      this.selected_single.text = (payload || {}).text || null
      this.selected_single.parent_id = (payload || {}).parent_id || null
      this.selected_single.element_type = (payload || {}).element_type || null
    },
    doSelectMultiple(payload) {
      const row = this.selected_multiple.find(i => i.value === (payload || {}).value || null)
      if (!row) {
        this.selected_multiple.push(
            {
              value: (payload || {}).value || null,
              text: (payload || {}).text || null,
              parent_id: (payload || {}).parent_id || null,
              element_type: (payload || {}).element_type || null
            }
        )
      }
    },
    doEmit(clear = false) {
      if (this.multiple) {
        const values = this.selected_multiple.map(i => i.value)
        this.$emit('input', values)
      } else {
        this.$emit('input', this.selected_single.value)
      }

      if (clear) {
        this.emitChanges()
      }
    },
    emitChanges() {
      let payload = null
      if (this.multiple) {
        payload = this.selected_multiple
      } else {
        payload = Object.assign({}, this.selected_single, {field: this.field_name})
      }

      this.$emit('selectChanged', payload)
    },
    doSelect(payload) {
      if (!payload) return

      const emit = (payload || {}).emit || false

      if (this.multiple) {
        this.doSelectMultiple(payload)
      } else {
        this.doSelectSingle(payload)
      }

      this.auto_filled = false

      this.$nextTick(() => {
        this.generate_input_text()

        if (emit) {
          this.doEmit()
          this.emitChanges()
        }

        if (!this.multiple) {
          this.focus_in = false
          this.show_dropdown = false
        }
      })

    },
    selectedIsEmpty() {
      if (this.multiple) {
        this.selected_multiple = []
      } else {
        this.selected_single.value = null
        this.selected_single.text = ''
        this.selected_single.parent_id = null
        this.selected_single.element_type = null
      }
      this.generate_input_text()
    },
    onChipCloseClick(payload) {
      if (!payload) return

      this.selected_multiple.splice(this.selected_multiple.indexOf(payload), 1)
      this.doEmit()

      this.$nextTick(() => {
        this.generate_input_text()
      })
    },
    clearAll() {
      if (this.disabled) return
      if (this.multiple) {
        this.selected_multiple.splice(0)
      } else {
        this.selected_single.value = null
        this.selected_single.text = ''
        this.selected_single.parent_id = null
        this.selected_single.element_type = null
      }

      this.doEmit(true)

      this.$nextTick(() => {
        this.generate_input_text()
      })
    },
    createNewModalElement() {
      this.modal_item_data = {}
      this.show_modal_dialog = true
    },
    editModalElement() {
      this.getDataForEdit()
    },
    afterModalClose(payload) {
      this.show_modal_dialog = false

      if ((payload || {payload: {}}).payload?.fetch) {
        this.currentAPI.get_by_id(this.value)
            .then(response => response.data)
            .then(data => {
              this.selected_single.value = data.value
              this.selected_single.text = data.text
              this.selected_single.parent_id = data.parent_id
              this.selected_single.element_type = data.element_type

              this.generate_input_text()
            })
            .finally(() => {
              this.value_loading = false

              this.fetchData()
            })
      }


    },
    getDataForEdit() {
      if (this.multiple) return
      this.currentAPI.get_one_by_id({element_id: this.value})
          .then(response => response.data)
          .then(data => {
            this.modal_item_data = data
            this.show_modal_dialog = true
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    }
  },
  created() {
    if (!this.use_parent_id) {
      this.fetchData()
    }

    this.watch_value_func()
    if (this.use_parent_id) {
      this.watch_parent_id_func()
    }
    this.watch_search_func()
  },
  beforeDestroy() {
    if (this.search_watcher) {
      this.search_watcher()
    }
    if (this.value_watcher) {
      this.value_watcher()
    }
    if (this.parent_id_watcher) {
      this.parent_id_watcher()
    }
  }
}
</script>


<style scoped lang="scss">
.select-search-input {
  display: block;
  width: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  outline: none;
  padding: 6px 18px 6px 12px;
  border-radius: 5px;
  color: #2a2a2a;
  background: #ffffff;
  font-size: .95rem;
  line-height: 1.3rem;
  transition: all .2s ease-in-out;
}

input :not(.select-search-input) {
  width: 84%;
  display: block;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, .2);
  padding: 2px 4px 2px 0;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: var(--v-success-base);
    font-weight: 500;
  }
}

.input-thin {
  border-bottom: 1px solid rgba(0, 0, 0, .2) !important;
}

.error-input {
  input {
    border-bottom: 2px solid #f09995;
  }
}

.select-wrapper {
  //position: fixed;
  background: white;
  //z-index: 1;
  //width: 400px;
  //max-width: 400px;
  //min-width: 360px;
  max-height: 400px;
  min-height: 60px;
  //display: flex;
  //flex: 1;
  overflow: overlay;

  .list-content {
    display: block !important;
    font-size: .86rem;

    &:deep(strong) {
      color: #4caf50;
      font-weight: 600;
    }
  }
}

</style>